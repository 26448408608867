import { useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntercom } from 'react-use-intercom';
import useIntercomIdentityVerification from '../../../services/third-party/intercom/useIntercomIdentityVerification';
import useClientLogger from '../../../services/useClientLogger';
import useCampaignPhasedAccess from '../../../services/user/useCampaignPhasedAccess';
import useUser from '../../../services/user/useUser';
import { setCampaignPhasedAccess, setInitialState, setLimit, setUserLoading } from '../../../store/user/actions';
import { useErrorHandler } from '../../../utils/error/error-handler';
import { assertIsError, assertIsFetchError } from '../../../utils/type_assertions';
import { Seat, User, PreferredPaymentMethod } from '../../interfaces';
import { FullStory } from '@fullstory/browser';

export default function useUserProfile() {
  const dispatch = useDispatch();
  const { parseResponseError } = useErrorHandler();
  const { update: intercomIdentify } = useIntercom();
  const { getIdentityVerificationHash } = useIntercomIdentityVerification();
  const { getUserProfile, getSpendingLimit } = useUser();
  const { getUserAccessToActiveCampaigns } = useCampaignPhasedAccess();
  const { log } = useClientLogger();

  const [loading, setLoading] = useState(true);

  async function fetchUserProfile() {
    try {
      await Promise.all([
        getUserProfile().then((userProfileRes: any) => {
          const user = userProfileRes?.user as User;
          const uac = userProfileRes?.uac as any;
          const accountTrustStatus = userProfileRes?.context_account?.trust_status;
          dispatch(
            setInitialState(
              { ...user, trust_status: accountTrustStatus },
              userProfileRes?.seats as Seat[],
              userProfileRes?.seats[0] as Seat,
              userProfileRes?.user.user_payment_preferences as PreferredPaymentMethod[]
            )
          );
          identify(user);
        }),
        getSpendingLimit().then((userSpendLimit) => dispatch(setLimit(userSpendLimit))),
        getUserAccessToActiveCampaigns().then((userCampaignPhasedAccess) =>
          dispatch(setCampaignPhasedAccess(userCampaignPhasedAccess))
        ),
      ]);
      dispatch(setUserLoading(false));
      setLoading(false);
    } catch (error) {
      assertIsFetchError(error);

      const errorDetails = await error.response.json();
      parseResponseError({
        success: error.response.ok,
        statusCode: Number(error.response.status),
        msg: errorDetails.msg,
        error: errorDetails,
      });
      dispatch(setUserLoading(false));
      setLoading(false);
    }
  }

  async function identify(user: User) {
    try {
      const identityVerificationHash = await getIdentityVerificationHash();
      intercomIdentify({
        userId: user.user_id,
        userHash: identityVerificationHash,
      });

      FullStory('setIdentity', {
        uid: user.user_id,
        properties: {
          email: user.email_address,
          displayName: [user.first_name, user.last_name].join(' '),
        },
      });
    } catch (error) {
      assertIsError(error);
      log(`Intercom identify error for user_id: ${user.user_id}!`, error);
    }
  }

  useLayoutEffect(() => {
    fetchUserProfile();
  }, []);

  return { loading, refetchUserProfile: fetchUserProfile };
}
